<script>
export default {
  name: 'ViewExternalWallets',

  data() {
    return {
      loading: false,
      selectedWallet: null,
      newWalletName: null
    }
  },

  computed: {
    hasNewName() {
      if(!this.newWalletName?.length) return false

      return true
    },

    hasWallets() {
      return !!this.wallets.length
    },

    wallets() {
      return this.$externalWallet?.wallets ?? []
    }
  },

  methods: {
    async deleteWallet(id) {
      try {
        await this.$externalWallet.deleteWallet({ id })

        this.$notification({
          title: this.$t('conn3ct-wallet.external_wallet_deleted'),
          type: this.$pepper.Intent.SUCCESS
        })

        this.init()
      } catch(e) {
        this.$notification({
          title: this.$t('conn3ct-wallet.external_wallet_delete_error'),
          type: this.$pepper.Intent.ERROT
        })
      }
    },

    async init() {
      try {
        this.loading = true
        await this.$externalWallet.getWallets()
      } catch(e) {
        $console.error(e)
      } finally {
        this.loading = false
      }
    },

    async linkWallet() {
      try {
        const { token } = await this.$externalWallet.generateToken({ redirectUrl: '/dashboard' })
        this.$router.push({ name: 'sayl-connect_user-link-wallet', query: { token }})
      } catch(e) {
        $console.error(e)
      }
    },

    onDeleteWallet(id) {
      this.$confirm({
        onPrimary: () => this.deleteWallet(id),
        primaryAction: this.$t('conn3ct-wallet.settings_wallet_delete'),
        description: this.$t('conn3ct-wallet.settings_wallet_delete_description'),
        secondaryAction: this.$t('conn3ct-wallet.cancel'),
      })
    },

    onCloseEdit() {
      this.selectedWallet = null
    },

    onEditWallet(wallet) {
      this.selectedWallet = wallet
    },

    async onSubmitEdit() {
      try {
        await this.$externalWallet.updateName({
          name: this.hasNewName ? this.newWalletName : this.selectedWallet.name,
          id: this.selectedWallet.id
        })

        this.init()
        this.onCloseEdit()

        this.$notification({
          title: this.$t('conn3ct-wallet.external_wallet_updated'),
          type: this.$pepper.Intent.SUCCESS
        })
      } catch(e) {
        this.$notification({
          title: this.$t('conn3ct-wallet.external_wallet_edit_error'),
          type: this.$pepper.Intent.ERROR
        })
      }
    }
  },

  mounted() {
    window.scrollTo(0, 0)
    this.init()
  }
}
</script>

<template>
  <layout-page
    :back="{ name: 'sayl-connect_user-settings' }"
    class="module-user external-wallets"
    :loading="loading"
    :title="$t('conn3ct-wallet.external_wallet_settings_title')"
  >
    <div class="external-wallets__body">
      <ui-panel v-if="!hasWallets">
        <template v-slot:header>
          <h4 class="title">{{ $t('conn3ct-wallet.external_wallet_no_wallets_title') }}</h4>
        </template>

        <div class="body">
          <p>{{ $t('conn3ct-wallet.external_wallet_no_wallets_description') }}</p>

          <actions-button
            @click="linkWallet"
            :appearance="$pepper.Appearance.PRIMARY"
            class="external-wallets__action add"
          >{{ $t('conn3ct-wallet.link_wallet') }}</actions-button>
        </div>
      </ui-panel>

      <div
        v-else
        class="external-wallets__list"
      >
        <ui-panel
          v-for="wallet in wallets"
          class="external-wallets__item"
          :key="wallet.id"
        >
          <div class="external-wallets__item-body body">
            <span class="external-wallets__name">{{ wallet.name }}</span>

            <div class="external-wallets__actions">
              <actions-button
                @click="onEditWallet(wallet)"
                class="external-wallets__action"
                :size="$pepper.Size.S"
              >{{ $t('conn3ct-wallet.external_wallet_edit') }}</actions-button>

              <actions-button
                @click="onDeleteWallet(wallet.id)"
                :appearance="$pepper.Appearance.PRIMARY"
                class="external-wallets__action delete"
                :size="$pepper.Size.S"
              >
                <div class="external-wallets__action-icon">
                  <icon-trash />
                </div>
              </actions-button>
            </div>
          </div>
        </ui-panel>
      </div>
    </div>

    <popins-modal
      v-if="selectedWallet"
      :closable="false"
      position="middle-center"
      :size="$pepper.Size.XS"
      :visible="!!selectedWallet"
    >
      <template v-slot:header>
        <h4 class="external-wallets__edit-title">{{ selectedWallet.name }}</h4>
      </template>

      <div class="external-wallets__edit-body">
        <forms-input
          v-model="newWalletName"
          :placeholder="selectedWallet.name"
        >{{ $t('conn3ct-wallet.link_wallet_name_lbl') }}</forms-input>
      </div>

      <template v-slot:footer>
        <div class="external-wallets__edit-footer">
          <actions-button
            @click.prevent="onCloseEdit"
            class="external-wallets__edit-cancel"
          >{{ $t('conn3ct-wallet.edit_wallet_cancel_lbl') }}</actions-button>

          <actions-button
            @click.prevent="onSubmitEdit"
            :appearance="$pepper.Appearance.PRIMARY"
            class="external-wallets__edit-submit"
            :disabled="!hasNewName"
          >{{ $t('conn3ct-wallet.edit_wallet_submit_lbl') }}</actions-button>
        </div>
      </template>
    </popins-modal>
  </layout-page>
</template>
